import React from 'react';
import SVG from 'react-inlinesvg';

import Stores from '@base/elements/store/store-buttons';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import plusIcon from '@img/icons/plus.svg';
import GameModal from './game-modal';
import GameAccordion from './game-accordion';

function GameCard({ game, index, pageContext, globalData, inclusions }) {
  const modalPreOrderContent = () => (
    <GameModal data={game} type='preorder' strings={globalData.stringsAll} />
  );
  const modalEditionContent = () => (
    <GameModal
      data={game}
      type='editions'
      strings={globalData.stringsAll}
      inclusions={inclusions}
    />
  );

  const displayStores = (target) => {
    const storesBtn = document.getElementById(`stores-btn-${target}`);
    const storesContainer = document.getElementById(`stores-${target}`);
    if (storesContainer.classList.contains('active')) {
      storesBtn.classList.remove('active');
      storesContainer.classList.remove('active');
    } else {
      storesBtn.classList.add('active');
      storesContainer.classList.add('active');
    }
  };

  return (
    <div
      key={`gameCard_${index}`}
      className='buy-card flex h-full w-full flex-col rounded-[3rem] bg-black/50 px-5 py-10 lg:w-1/2 lg:px-10 lg:py-16'
    >
      <div className='buy-card-header px-6'>
        <Image media={game?.keyart} alt={game?.title} className='w-full' />
        <h2 className='-mt-6 text-center text-sm lg:text-lg'>
          <span>{game.title}</span>
        </h2>
      </div>
      <div className='buy-card-content mt-10 flex flex-col justify-between text-white'>
        <div className='flex flex-col'>
          {/* <div className='buy-card-emphasis relative mb-7'>
            <Button
              trigger='dynamicModal'
              pageContext={pageContext}
              className='button-learnmore flex !w-full justify-between'
              modalContent={{
                className: '',
                content: modalPreOrderContent,
              }}
              trackable
              eventAction='pop_up_buy_now_bonus'
              eventPage={pageContext.pageDataId}
            >
              <h3 className='plain with-separator mb-10 grow text-left !text-base uppercase'>
                {globalData.stringsAll.cta.preOrderToReceive}
              </h3>
              <SVG
                cacheRequests
                src={plusIcon}
                className='absolute right-0 top-0'
              />
            </Button>
            <ul className='px-10 text-base'>
              {game.preOrderBonus?.map((bonus, l) => (
                <li key={`gameBonus_${index}_${l}`}>
                  {`${bonus.title} ${bonus.subtitle}`}
                </li>
              ))}
            </ul>
          </div> */}
          <div className='mb-7 px-6'>
            {game?.gameID === 'base-deluxe' ? (
              <Button
                trigger='dynamicModal'
                pageContext={pageContext}
                className='button-learnmore flex !w-full justify-between'
                modalContent={{
                  className: '',
                  content: modalEditionContent,
                }}
                trackable
                eventAction='pop_up_deluxe_edition'
                eventPage={pageContext.pageDataId}
              >
                <h3 className='plain with-separator mb-12 w-full pr-12 text-left !text-base normal-case leading-[1.1]'>
                  {globalData.stringsLabels.includedInDeluxe}
                </h3>
                <SVG
                  cacheRequests
                  src={plusIcon}
                  className='absolute right-0 top-0'
                />
              </Button>
            ) : (
              <h3 className='plain with-separator mb-12 w-full pr-12 text-left !text-base normal-case leading-[1.1]'>
                {globalData.stringsLabels.includedInStandard}
              </h3>
            )}
            <ul className='px-10 text-sm'>
              {inclusions?.standard && inclusions?.deluxe ? (
                <>
                  {inclusions?.standard?.[0].map((inclusion, l) => (
                    <li key={`gameBaseInclusion_${index}_${l}`}>
                      {inclusion.title}
                    </li>
                  ))}
                  {inclusions?.deluxe?.[0].map((inclusion, l) => (
                    <li
                      key={`gameDeluxeInclusion_${index}_${l}`}
                      className={
                        game?.gameID !== 'base-deluxe' && 'pointer-events-none invisible hidden opacity-0 lg:flex'
                      }
                    >
                      {inclusion.title}
                    </li>
                  ))}
                </>
              ) : (
                game.inclusions?.map((inclusion, l) => (
                  <li key={`gameInclusion_${index}_${l}`}>{inclusion.title}</li>
                ))
              )}
            </ul>
          </div>
          <GameAccordion
            data={game}
            pageContext={pageContext}
            globalData={globalData}
          />
        </div>
        <Button
          id={`stores-btn-${game.gameID}`}
          trigger='clickAction'
          pageContext={pageContext}
          className='mx-auto mt-10'
          trackable
          eventAction='buy_now_select_platform'
          eventPage={pageContext.pageDataId}
          onClick={() => displayStores(game.gameID)}
        >
          <span>{globalData.stringsAll.cta.selectPlatform}</span>
        </Button>
      </div>
      <div
        id={`stores-${game.gameID}`}
        className='store-list-wrapper overflow-hidden'
      >
        <Stores
          pageContext={pageContext}
          layout='cta'
          product={game.gameID}
          gameData={globalData.gameData}
          className='m-0 p-0'
          projectOrder
          eventAction={pageContext?.dataLayer?.storeLinkModal?.event}
          eventPage={pageContext.pageDataId}
          eventSection=''
        />
      </div>
    </div>
  );
}

export default GameCard;
